import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;

export const Content = styled.div`
    width: 40%;
    padding: 20px;
    background: #1a1a1a;
    border-radius: 10px;
    position: relative;
    margin-left: calc(${({ right }) => (right ? "50% + 65px" : "10% - 60px")});
    @media screen and (max-width: 1000px) {
        width: calc(100% - 90px);
        margin-left: auto;
    }
    &:before {
        content: "";
        position: absolute;
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid #1a1a1a;
        ${({ right }) => (right ? "left: -20px" : "right: -20px;")};
        transform: rotate(${({ right }) => (right ? "-90deg" : "90deg")});
        @media screen and (max-width: 1000px) {
            left: -20px;
            right: auto;
            transform: rotate(-90deg);
        }
        @media screen and (max-width: 500px) {
            top: 10px;
            left: -16px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 16px solid #1a1a1a;
        }
    }
    @media screen and (max-width: 500px) {
        width: calc(100% - 70px);
    }
`;

export const Heading = styled.div`
    color: #fda722;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-size: 25px;
    @media screen and (max-width: 500px) {
        font-size: 20px;
    }
`;

export const List = styled.ol``;

export const ListItem = styled.li`
    font-size: 18px;
    @media screen and (max-width: 500px) {
        font-size: 16px;
    }
`;

export const Line = styled.div`
    ${({ last }) => last && "display: none"}
    width: 2px;
    position: absolute;
    height: calc(100% + 30px);
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    @media screen and (max-width: 1000px) {
        left: 30px;
        transform: translateX(0%);
    }
    @media screen and (max-width: 500px) {
        left: 20px;
    }
`;

export const Number = styled.div`
    width: 60px;
    height: 60px;
    background: #e89311;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    position: absolute;
    top: 0px;
    left: calc(50% - 30px);
    @media screen and (max-width: 1000px) {
        left: 0px;
        transform: translateX(0%);
    }
    @media screen and (max-width: 500px) {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
`;
