import styled from "styled-components";

export const Container = styled.div`
    padding: 0px 25px;
`;

export const Content = styled.div`
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;
