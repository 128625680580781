import styled from "styled-components";

export const Container = styled.div`
    padding: 0px 25px;
`;

export const Content = styled.div`
    max-width: 1200px;
    margin: auto;
`;

export const Questions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Question = styled.div`
    background: #1a1a1a;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 20px 30px;
`;

export const TitleExpandCollapse = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    cursor: pointer;
`;

export const Title = styled.div`
    font-size: 20px;
    @media screen and (max-width: 370px) {
        font-size: 16px;
    }
`;

export const ExpandCollapse = styled.div`
    width: 40px;
    height: 40px;
    background: #e49821;
    border-radius: 50%;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

export const Answer = styled.div`
    margin-top: 30px;
    color: rgb(125, 127, 150);
    @media screen and (max-width: 370px) {
        margin-top: 15px;
    }
`;
