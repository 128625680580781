import styled from "styled-components";

export const Container = styled.div`
    padding: 0px 15px;
`;

export const Content = styled.div`
    max-width: 900px;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 40px 150px;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px) {
        max-width: 750px;
    }
`;
