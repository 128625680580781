import React from "react";
import Button from "../Components/Button/Button";
import CourseCurriculum from "../Components/CourseCurriculum/CourseCurriculum";
import FAQ from "../Components/FAQ/FAQ";
import Hero from "../Components/Hero/Hero";
import Instructors from "../Components/Instructors/Instructors";
import Intro from "../Components/Intro/Intro";
import OurMonitors from "../Components/OurMonitors/OurMonitors";
import Reviews from "../Components/Reviews/Reviews";
import WhatIsIncluded from "../Components/WhatIsIncluded/WhatIsIncluded";
import WhatsApp from "../Components/WhatsApp/WhatsApp";

const Home = () => {
    return (
        <div>
            <WhatsApp />
            <Hero />
            <Button
                background="rgba(253, 167, 34, 0.75);"
                hoverBackground="#fda722"
                color="#fff"
                hoverColor=""
                link="https://www.google.com/"
                style={{ marginTop: "50px", marginBottom: "-50px" }}
                mediaQuery={`
                    @media screen and (max-width: 890px) {
                        margin-bottom: 0px!important;
                    }
                `}
            >
                Enroll Now
            </Button>
            <Intro />
            <OurMonitors />
            <WhatIsIncluded />
            <CourseCurriculum />
            <Button
                background="rgba(253, 167, 34, 0.75);"
                hoverBackground="#fda722"
                color="#fff"
                hoverColor=""
                link="https://www.google.com/"
                style={{ marginTop: "50px", marginBottom: "-50px" }}
                mediaQuery={`
                    @media screen and (max-width: 890px) {
                        margin-bottom: 0px!important;
                    }
                `}
            >
                Enroll Now
            </Button>
            <Reviews />
            {/* <Instructors /> */}
            <FAQ />
        </div>
    );
};

export default Home;
