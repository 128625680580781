import React from "react";
import styled from "styled-components";
import ManifestoPoints from "../Components/ManifestoPoints/ManifestoPoints";
import VideoPlayer from "../Components/VideoPlayer/VideoPlayer";

const Container = styled.div`
    padding: 8rem 25px 0px 25px;
    @media screen and (max-width: 400px) {
        padding-top: 4rem;
    }
`;

const Heading = styled.div`
    text-align: center;
    text-transform: capitalize;
    font-family: CalSans-SemiBold, sans-serif;
    margin-bottom: 40px;
    font-size: 3.25rem;
    @media screen and (max-width: 1200px) {
        font-size: 3.5rem;
    }
    @media screen and (max-width: 1025px) {
        font-size: 2.5rem;
    }
`;

const OurManifesto = () => {
    return (
        <Container>
            <Heading>Founder's Note</Heading>
            <VideoPlayer url="https://monitorsvideos.s3.ir-thr-at1.arvanstorage.com/Podcast_1.mp4" />
            <ManifestoPoints />
        </Container>
    );
};

export default OurManifesto;
