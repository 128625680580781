import styled from "styled-components";

export const Container = styled.div`
    text-align: center;
    display: inline-block;
`;

export const Icon = styled.div`
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #575757;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: #e89311;
        top: 10px;
    }
    & svg {
        position: relative;
        z-index: 1;
        font-size: ${({ iconFontSize }) => iconFontSize}px;
    }
`;

export const Number = styled.div`
    font-family: "MuseoModerno", cursive;
    font-size: 50px;
`;

export const Text = styled.div`
    font-weight: 500;
    font-size: 25px;
    line-height: 1.2;
`;
