import styled from "styled-components";

export const Container = styled.div`
    padding: 0px 25px;
    overflow: hidden;
`;

export const Content = styled.div`
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
    @media screen and (max-width: 650px) {
        gap: 50px;
    }
`;
