import React from "react";
import {
    Container,
    Content,
    ManifestoPoint,
    ManifestoPointHeading,
} from "./styles";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const ManifestoPoints = () => {
    const points = [
        {
            heading: "Founder's Note",
            video: "https://monitorsvideos.s3.ir-thr-at1.arvanstorage.com/Sushant%20-%20Directors%20Cut.mp4",
            videoPoster: "",
        },
        {
            heading: "Founder's Note",
            video: "https://monitorsvideos.s3.ir-thr-at1.arvanstorage.com/Sushant%20-%20Directors%20Cut.mp4",
            videoPoster: "",
        },
        {
            heading: "Founder's Note",
            video: "https://monitorsvideos.s3.ir-thr-at1.arvanstorage.com/Sushant%20-%20Directors%20Cut.mp4",
            videoPoster: "",
        },
    ];
    return (
        <Container>
            <Content>
                {points.map((point, index) => (
                    <ManifestoPoint key={index}>
                        <ManifestoPointHeading>
                            {point.heading}
                        </ManifestoPointHeading>
                        <VideoPlayer url={point.video} />
                    </ManifestoPoint>
                ))}
            </Content>
        </Container>
    );
};

export default ManifestoPoints;
