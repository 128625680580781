import styled from "styled-components";

export const Container = styled.div`
    background: #1e1e1e;
    margin-top: 100px;
    border-top: 10px solid #fda722;
    padding: 100px 25px 150px;
    @media screen and (max-width: 890px) {
        margin-top: 50px;
        border-top: 5px solid #fda722;
    }
    @media screen and (max-width: 800px) {
        padding: 50px 15px 100px;
    }
    @media screen and (max-width: 530px) {
        padding: 50px 15px;
    }
`;

export const Content = styled.div`
    max-width: 1500px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
    @media screen and (max-width: 530px) {
        align-items: center;
        text-align: center;
        gap: 10px;
    }
`;

export const Logo = styled.img``;

export const MailUs = styled.div`
    margin-top: 15px;
    @media screen and (max-width: 800px) {
        margin-top: 0px;
    }
`;

export const MailUsHeading = styled.div`
    font-family: "Clash Display";
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    text-transform: capitalize;
    color: #d8d8d8;
    @media screen and (max-width: 800px) {
        font-size: 20px;
    }
`;

export const Email = styled.div`
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    font-size: 60px;
    line-height: 110px;
    text-decoration-line: underline;
    color: #fda722;
    @media screen and (max-width: 800px) {
        font-size: 40px;
        line-height: 80px;
    }
    @media screen and (max-width: 530px) {
        font-size: 25px;
        line-height: 50px;
    }
    @media screen and (max-width: 350px) {
        font-size: 18px;
    }
`;

export const Socials = styled.div`
    display: flex;
    gap: 10px;
`;

export const Social = styled.div`
    font-size: 40px;
    color: #ffd359;
    @media screen and (max-width: 800px) {
        font-size: 30px;
    }
`;
