import styled from "styled-components";

export const Container = styled.div`
    max-width: 400px;
    padding: 25px;
    background: #1a1a1a;
    border: 1px solid #707070;
    border-radius: 10px;
`;

export const User = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    @media screen and (max-width: 350px) {
        flex-direction: column;
        text-align: center;
    }
`;

export const UserPic = styled.div`
    width: 80px;
    height: 80px;
    background: url("${({ url }) => url}");
    background-size: cover;
    border-radius: 50%;
`;

export const UserNameProfession = styled.div``;

export const UserName = styled.div`
    font-size: 1.25rem;
`;

export const UserProfession = styled.div`
    color: #fda722;
`;

export const StarRating = styled.div`
    margin: 10px 0px;
    & > div {
        @media screen and (max-width: 350px) {
            justify-content: center;
        }
    }
    & span {
        margin-right: 8px;
    }
    & span:last-child {
        margin-right: 0px;
    }
`;

export const Review = styled.div`
    @media screen and (max-width: 350px) {
        text-align: center;
    }
`;
