import styled from "styled-components";

export const Container = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const Content = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: relative;
    background: url(${({ image }) => image});
    background-color: #1a1a1a;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 650px) {
        width: 150px;
        height: 150px;
    }
`;

export const PlayPauseButton = styled.div`
    position: absolute;
    font-size: 130px;
    color: #fda722;
    top: 44%;
    right: -60px;
    @media screen and (max-width: 650px) {
        font-size: 100px;
        right: -40px;
    }
`;

export const Video = styled.video`
    border-radius: 50%;
    object-fit: cover;
    width: 200px;
    height: 200px;
    @media screen and (max-width: 650px) {
        width: 150px;
        height: 150px;
    }
`;

export const KnowMore = styled.div`
    text-align: center;
    color: #fda722;
`;

export const MonitorName = styled.div`
    text-align: center;
    font-size: 25px;
    color: #fda722;
`;
