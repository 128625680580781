import { Slide } from "react-reveal";
import styled from "styled-components";

export const Container = styled.div`
    font-family: CalSans-SemiBold, sans-serif;
    margin: 100px auto 68px auto;
    font-size: 2rem;
    text-align: center;
    text-transform: ${({ capitalize }) =>
        capitalize ? "capitalize" : "uppercase"};
    max-width: ${({ maxWidth }) => maxWidth}px;
    & span {
        color: #fda722;
        text-transform: ${({ capitalize }) =>
            capitalize ? "capitalize" : "uppercase"};
    }
    @media screen and (max-width: 890px) {
        margin: 50px auto 34px auto;
    }
    @media screen and (max-width: 700px) {
        font-size: 1.5rem;
    }
`;

export const SectionHeading = ({ children, maxWidth, capitalize }) => (
    <Slide left unmountOnExit>
        <Container maxWidth={maxWidth} capitalize={capitalize}>
            {children}
        </Container>
    </Slide>
);
