import "./App.css";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import OurManifesto from "./Pages/OurManifesto";
import HamMenu from "./Components/HamMenu/HamMenu";
import { useEffect, useState } from "react";
import { Slide } from "react-reveal";

function App() {
    const [hamMenuOpen, setHamMenuOpen] = useState(false);
    useEffect(() => {
        document.body.style.overflow = hamMenuOpen ? "hidden" : "auto";
    }, [hamMenuOpen]);
    return (
        <div className="app">
            <Router>
                <div style={{ position: "fixed", top: "80px", zIndex: 4 }}>
                    <Slide left when={hamMenuOpen} mountOnEnter unmountOnExit>
                        <HamMenu setHamMenuOpen={setHamMenuOpen} />
                    </Slide>
                </div>
                <Header
                    hamMenuOpen={hamMenuOpen}
                    setHamMenuOpen={setHamMenuOpen}
                />
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route
                        path="/our-manifesto"
                        exact
                        element={<OurManifesto />}
                    />
                </Routes>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
