import React from "react";
import styled from "styled-components";

const Container = styled.img`
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    z-index: 4;
    cursor: pointer;
`;

const WhatsApp = () => {
    return (
        <a href="https://web.whatsapp.com/" target="_blank" rel="noreferrer">
            <Container src="https://img.icons8.com/color/344/whatsapp--v1.png" />
        </a>
    );
};

export default WhatsApp;
