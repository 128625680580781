import styled from "styled-components";

export const Container = styled.div`
    padding: 25px;
    width: 100vw;
    background: #1a1a1a;
    height: calc(100vh - 80px);
    text-align: center;
`;

export const Link = styled.div`
    margin-bottom: 20px;
    font-weight: 400;
    font-family: CalSans-SemiBold, sans-serif;
    &:hover {
        color: #fda722;
    }
`;

export const Socials = styled.div`
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    gap: 15px;
    color: #707070;
`;
