import React, { useEffect, useRef } from "react";
import {
    Container,
    Content,
    KnowMore,
    MonitorName,
    PlayPauseButton,
    Video,
} from "./styles";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { useState } from "react";

const Monitor = ({ monitor }) => {
    const [videoPlaying, setVideoPlaying] = useState();
    const video = useRef(null);
    useEffect(() => {
        videoPlaying && video.current.play();
    }, [videoPlaying]);
    return (
        <>
            <Container>
                <Content
                    image={monitor.image}
                    onClick={() => setVideoPlaying(!videoPlaying)}
                >
                    {videoPlaying && <Video src={monitor.video} ref={video} />}
                    <PlayPauseButton>
                        {videoPlaying ? <BsPauseFill /> : <BsPlayFill />}
                    </PlayPauseButton>
                </Content>
                <div>
                    <KnowMore>Play To Know More</KnowMore>
                    <MonitorName>{monitor.name}</MonitorName>
                </div>
            </Container>
        </>
    );
};

export default Monitor;
