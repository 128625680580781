import styled from "styled-components";

export const Container = styled.div`
    padding: 0px 25px;
`;

export const Content = styled.div`
    max-width: 1200px;
    margin: auto;
    display: flex;
    gap: 25px 40px;
    flex-wrap: wrap;
    justify-content: center;
`;

export const InstructorVideo = styled.div`
    padding: 0px 20px;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    & > svg {
        font-size: 40px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        &:hover {
            color: #fda722;
        }
    }
`;
