import React, { useState } from "react";
import {
    Answer,
    Container,
    Content,
    ExpandCollapse,
    Question,
    Questions,
    Title,
    TitleExpandCollapse,
} from "./styles";
import { SectionHeading } from "../../globalStyles";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import { Fade } from "react-reveal";
import Expand from "react-expand-animated";

const FAQ = () => {
    const [expandedQuestion, setExpandedQuestion] = useState(null);
    const questions = [
        {
            title: "What if I have zero knowledge about finance?",
            answer: `Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed vestibulum dictum nulla, sed vehicula
                            lacus interdum non. Maecenas imperdiet interdum nibh
                            eget suscipit. Nam hendrerit gravida diam, quis
                            lobortis lectus scelerisque nec. Sed id malesuada
                            elit. Suspendisse tristique mi sit amet metus
                            hendrerit mattis.`,
        },
        {
            title: "What happens when I register?",
            answer: `Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed vestibulum dictum nulla, sed vehicula
                            lacus interdum non. Maecenas imperdiet interdum nibh
                            eget suscipit. Nam hendrerit gravida diam, quis
                            lobortis lectus scelerisque nec. Sed id malesuada
                            elit. Suspendisse tristique mi sit amet metus
                            hendrerit mattis.`,
        },
        {
            title: "Do we get recordings?",
            answer: `Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed vestibulum dictum nulla, sed vehicula
                            lacus interdum non. Maecenas imperdiet interdum nibh
                            eget suscipit. Nam hendrerit gravida diam, quis
                            lobortis lectus scelerisque nec. Sed id malesuada
                            elit. Suspendisse tristique mi sit amet metus
                            hendrerit mattis.`,
        },
        {
            title: "What if I have any doubts?",
            answer: `Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed vestibulum dictum nulla, sed vehicula
                            lacus interdum non. Maecenas imperdiet interdum nibh
                            eget suscipit. Nam hendrerit gravida diam, quis
                            lobortis lectus scelerisque nec. Sed id malesuada
                            elit. Suspendisse tristique mi sit amet metus
                            hendrerit mattis.`,
        },
        {
            title: "What all will happen in the community?",
            answer: `Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed vestibulum dictum nulla, sed vehicula
                            lacus interdum non. Maecenas imperdiet interdum nibh
                            eget suscipit. Nam hendrerit gravida diam, quis
                            lobortis lectus scelerisque nec. Sed id malesuada
                            elit. Suspendisse tristique mi sit amet metus
                            hendrerit mattis.`,
        },
        {
            title: "What happens when I register?",
            answer: `Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed vestibulum dictum nulla, sed vehicula
                            lacus interdum non. Maecenas imperdiet interdum nibh
                            eget suscipit. Nam hendrerit gravida diam, quis
                            lobortis lectus scelerisque nec. Sed id malesuada
                            elit. Suspendisse tristique mi sit amet metus
                            hendrerit mattis.`,
        },
    ];
    return (
        <Container>
            <SectionHeading maxWidth="300">
                frequently asked
                <span> questions ❓ </span>
            </SectionHeading>
            <Fade bottom>
                <Content>
                    <Questions>
                        {questions.map((question, index) => (
                            <Question key={index}>
                                <TitleExpandCollapse
                                    onClick={() =>
                                        expandedQuestion === index
                                            ? setExpandedQuestion(null)
                                            : setExpandedQuestion(index)
                                    }
                                >
                                    <Title>{question.title}</Title>
                                    <ExpandCollapse>
                                        {expandedQuestion === index ? (
                                            <BsChevronUp />
                                        ) : (
                                            <BsChevronDown />
                                        )}
                                    </ExpandCollapse>
                                </TitleExpandCollapse>
                                <Expand open={expandedQuestion === index}>
                                    <Answer>{question.answer}</Answer>
                                </Expand>
                            </Question>
                        ))}
                    </Questions>
                </Content>
            </Fade>
        </Container>
    );
};

export default FAQ;
