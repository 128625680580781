import React from "react";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import {
    Bottom,
    // ButtonGroup,
    Container,
    Content,
    CourseDetail,
    CourseDetailContent,
    CourseDetailDesc,
    CourseDetailIcon,
    CourseDetails,
    CourseDetailTitle,
    Desc,
    Heading,
    HeadingDesc,
    Top,
} from "./styles.jsx";
import Fade from "react-reveal/Fade";
import { Zoom } from "react-reveal";

const Hero = () => {
    const courseDetails = [
        {
            title: "Date",
            desc: "20th, 21st & 22nd February 2022",
            icon: "date",
        },
        {
            title: "Time",
            desc: "9PM - 10PM",
            icon: "time",
        },
        {
            title: "Duration",
            desc: "3 Hours",
            icon: "duration",
        },
        {
            title: "Bonus",
            desc: "₹10,000",
            icon: "bonus",
        },
    ];
    return (
        <Container>
            <Content>
                <Top>
                    <HeadingDesc>
                        <Fade top>
                            <Heading>
                                Finance for <span>everyone</span>
                            </Heading>
                        </Fade>
                        {/* <Fade top>
                            <Desc>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Vestibulum eros tellus,
                            </Desc>
                        </Fade> */}
                    </HeadingDesc>
                    {/* <ButtonGroup>
                        <Button
                            background="#fff"
                            hoverBackground="#fda722"
                            color="#fda722"
                            hoverColor="#fff"
                            link="#curriculum"
                            isRoute
                        >
                            Enroll Now
                        </Button>
                    </ButtonGroup> */}
                </Top>
                <Zoom bottom>
                    <Bottom>
                        <VideoPlayer
                            posterURL="https://ik.imagekit.io/n9uyscagf/mainvideocover__vkv18xtC?ik-sdk-version=javascript-1.4.3&updatedAt=1657642618806"
                            url="https://res.cloudinary.com/djcidrtfx/video/upload/v1657258841/baskarbhai.mp4"
                        />
                    </Bottom>
                </Zoom>
                <CourseDetails>
                    {courseDetails.map(({ desc, icon, title }, index) => (
                        <CourseDetail key={index}>
                            <CourseDetailIcon>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        `/assets/images/${icon}.png`
                                    }
                                    alt="Date"
                                />
                            </CourseDetailIcon>
                            <CourseDetailContent>
                                <CourseDetailTitle>{title}</CourseDetailTitle>
                                <CourseDetailDesc>{desc}</CourseDetailDesc>
                            </CourseDetailContent>
                        </CourseDetail>
                    ))}
                </CourseDetails>
            </Content>
        </Container>
    );
};

export default Hero;
