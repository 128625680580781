import styled from "styled-components";

export const Container = styled.div`
    padding: 0px 15px;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 850px;
    gap: 40px;
    margin: auto;
`;
