import React from "react";
import { Container, Content } from "./styles";
import { SectionHeading } from "../../globalStyles";
import CurriculumCard from "../CurriculumCard/CurriculumCard";
import { Fade } from "react-reveal";

const CourseCurriculum = () => {
    const cards = [
        {
            heading: "Personal Finance",
            list: [
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
            ],
        },
        {
            heading: "Investments",
            list: [
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
            ],
        },
        {
            heading: "Crypto And De-Fi",
            list: [
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
                "List Item",
            ],
        },
    ];
    return (
        <Container>
            <SectionHeading>
                course
                <span> curriculum </span>
                📚
            </SectionHeading>
            {/* <Fade bottom> */}
            <Content>
                {cards.map((card, index) => (
                    <CurriculumCard
                        card={card}
                        key={index}
                        number={index + 1}
                        right={(index + 1) % 2 === 0}
                        last={index + 1 === cards.length}
                    />
                ))}
            </Content>
            {/* </Fade> */}
        </Container>
    );
};

export default CourseCurriculum;
