import React from "react";
import { NavLink } from "react-router-dom";
import Button from "../Button/Button";
import { Container, Link, Socials } from "./styles";
import { BsTwitter, BsLinkedin, BsYoutube } from "react-icons/bs";

const HamMenu = ({ setHamMenuOpen }) => {
    return (
        <Container>
            <NavLink
                to="/"
                onClick={() => setHamMenuOpen(false)}
                style={({ isActive }) => (isActive ? { color: "#fda722" } : {})}
            >
                <Link>Home</Link>
            </NavLink>
            <NavLink
                to="/our-manifesto"
                onClick={() => setHamMenuOpen(false)}
                style={({ isActive }) => (isActive ? { color: "#fda722" } : {})}
            >
                <Link>Our Manifesto</Link>
            </NavLink>
            <Button
                style={{
                    padding: "0.75rem 1rem",
                }}
                background="rgba(253, 167, 34, 0.75);"
                hoverBackground="#fda722"
                color="#fff"
                hoverColor=""
                link="https://www.google.com/"
            >
                Enroll Now
            </Button>
            <Socials>
                <BsTwitter />
                <BsLinkedin />
                <BsYoutube />
            </Socials>
        </Container>
    );
};

export default HamMenu;
