import styled from "styled-components";

export const Container = styled.div`
    margin-top: 100px;
    @media screen and (max-width: 890px) {
        margin-top: 50px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1000px;
    margin: auto;
`;

export const ManifestoPoint = styled.div`
    background: #1a1a1a;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 40px;
    @media screen and (max-width: 800px) {
        padding: 25px;
    }
`;

export const ManifestoPointHeading = styled.div`
    margin-bottom: 20px;
    color: #fda722;
    font-size: 2rem;
    @media screen and (max-width: 800px) {
        font-size: 28px;
    }
`;
