import styled from "styled-components";

export const Container = styled.div`
    padding-top: 8rem;
    position: relative;
    /* background-image: url("https://deothemes.com/envato/xhibiter/html/img/gradient_dark.jpg"); */
    background-image: url("/assets/images/herobg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -750px -50px;
    @media screen and (max-width: 800px) {
    }
    @media screen and (max-width: 400px) {
        padding-top: 4rem;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(17, 17, 17, 0.74);
        backdrop-filter: blur(70px);
        z-index: 1;
    }
`;

export const Content = styled.div`
    max-width: 1200px;
    margin: auto;
    padding: 0px 2rem;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 1200px) {
        max-width: 1025px;
    }
    @media screen and (max-width: 1025px) {
        max-width: 768px;
    }
    @media screen and (max-width: 768px) {
        max-width: 575px;
    }
`;

export const Top = styled.div`
    padding: 3rem 0px;
    text-align: center;
`;

export const HeadingDesc = styled.div`
    max-width: 600px;
    margin: auto;
    margin-bottom: 1rem;
`;

export const Heading = styled.div`
    text-transform: capitalize;
    font-family: CalSans-SemiBold, sans-serif;
    font-size: 3.25rem;
    @media screen and (max-width: 1200px) {
        font-size: 3.5rem;
    }
    @media screen and (max-width: 1025px) {
        font-size: 2.5rem;
    }
    & span {
        color: #fda722;
        text-transform: uppercase;
    }
`;

export const Desc = styled.div`
    font-size: 1.25rem;
    color: rgb(196 197 207);
`;

// export const ButtonGroup = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 1rem;
//     flex-wrap: wrap;
// `;

export const Bottom = styled.div``;

export const CourseDetails = styled.div`
    padding: 32px;
    background: #221f1f;
    border-radius: 18px;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    @media screen and (max-width: 800px) {
        padding: 25px;
    }
`;

export const CourseDetail = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    flex: 1;
`;

export const CourseDetailIcon = styled.div`
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    background: #413d3d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
        width: 57px;
        height: 57px;
    }
`;

export const CourseDetailContent = styled.div`
    color: #ffffff;
    letter-spacing: -0.02em;
    min-width: 120px;
`;

export const CourseDetailTitle = styled.div`
    font-weight: 600;
    font-size: 22px;
    line-height: 136%;
    @media screen and (max-width: 800px) {
        font-size: 20px;
    }
`;

export const CourseDetailDesc = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 116%;
    @media screen and (max-width: 800px) {
        font-size: 16px;
    }
`;
