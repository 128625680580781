import React, { useEffect, useState } from "react";
import {
    Container,
    Content,
    Logo,
    Link,
    Links,
    LinksButtons,
    HeaderButtons,
    HeaderButton,
    HamMenu,
} from "./styles";
import logo from "../../logo.png";
import { NavLink } from "react-router-dom";
import { RiSunFill } from "react-icons/ri";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import Button from "../Button/Button";

const Header = ({ hamMenuOpen, setHamMenuOpen }) => {
    const [blur, setBlur] = useState(false);
    useEffect(() => {
        const changeBlur = () => {
            if (window.scrollY < 10) {
                blur || setBlur(false);
            } else {
                blur || setBlur(true);
            }
        };
        changeBlur();
        window.addEventListener("scroll", changeBlur);
        return () => {
            window.removeEventListener("scroll", () => {});
        };
    }, []);
    return (
        <Container hamMenuOpen={hamMenuOpen} blur={blur}>
            <Content>
                <NavLink to="/">
                    <Logo src={logo} />
                </NavLink>
                <LinksButtons>
                    <Links>
                        <NavLink
                            to="/"
                            style={({ isActive }) =>
                                isActive ? { color: "#fda722" } : {}
                            }
                        >
                            <Link>Home</Link>
                        </NavLink>
                        <NavLink
                            to="/our-manifesto"
                            style={({ isActive }) =>
                                isActive ? { color: "#fda722" } : {}
                            }
                        >
                            <Link>Our Manifesto</Link>
                        </NavLink>
                    </Links>
                    <Button
                        style={{ width: "100%", padding: "0.75rem 1rem" }}
                        background="rgba(253, 167, 34, 0.75);"
                        hoverBackground="#fda722"
                        color="#fff"
                        hoverColor=""
                        link="https://www.google.com/"
                    >
                        Enroll Now
                    </Button>
                    <HamMenu>
                        <HeaderButton
                            onClick={() => setHamMenuOpen(!hamMenuOpen)}
                        >
                            {hamMenuOpen ? (
                                <MdClose fontSize={25} />
                            ) : (
                                <HiMenuAlt3 />
                            )}
                        </HeaderButton>
                    </HamMenu>
                </LinksButtons>
            </Content>
        </Container>
    );
};

export default Header;
