import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
    font-weight: 600;
    padding: 0.75rem 2rem;
    text-align: center;
    width: 9rem;
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 30px;
    margin: auto;
    ${({ colors }) => `
        background: ${colors.background};
        color: ${colors.color};
        &:hover {
            background: ${colors.hoverBackground};
            color: ${colors.hoverColor};
        }
    `}
    ${({ mediaQuery }) => mediaQuery}
`;

const Button = ({
    link,
    isRoute,
    background,
    hoverBackground,
    color,
    hoverColor,
    children,
    style,
    mediaQuery,
}) => {
    const wrapper = (
        <Container
            style={style}
            colors={{ background, hoverBackground, color, hoverColor }}
            mediaQuery={mediaQuery}
        >
            {children}
        </Container>
    );
    return isRoute ? (
        <Link to={link}>{wrapper}</Link>
    ) : (
        <a href={link} rel="noreferrer" target="_blank">
            {wrapper}
        </a>
    );
};

export default Button;
