import styled from "styled-components";

export const Container = styled.div`
    background: #1a1a1a;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
`;

export const InstructorImg = styled.div`
    border-radius: 20px;
    width: 150px;
    height: 160px;
    background: url(${({ url }) => url});
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 24px;
`;

export const Name = styled.div`
    font-family: CalSans-SemiBold, sans-serif;
    font-size: 1.125rem;
    text-transform: capitalize;
`;

export const Profession = styled.div`
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: -0.025em;
    color: rgb(125 127 150);
`;

export const Socials = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: #a1a2b3;
    & svg:hover {
        color: #fff;
    }
`;
