import React from "react";
import { Fade, Zoom } from "react-reveal";
import {
    Container,
    Content,
    Heading,
    Line,
    List,
    ListItem,
    Number,
} from "./styles";

const CurriculumCard = ({ right, last, number, card }) => {
    return (
        <Container>
            <Fade delay={2} left mirror={right || window.innerWidth <= 1000}>
                <Content right={right}>
                    <Heading>{card.heading}</Heading>
                    <List>
                        {card.list.map((listItem, index) => (
                            <ListItem key={index}>{listItem}</ListItem>
                        ))}
                    </List>
                </Content>
            </Fade>
            <Line last={last} />
            <Number>{number}</Number>
        </Container>
    );
};

export default CurriculumCard;
