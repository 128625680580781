import React, { useState } from "react";
import { Container, Icon, Number, Text } from "./styles";
import AnimatedNumber from "animated-number-react";
import { Waypoint } from "react-waypoint";

const WhatIsIncludedCard = ({ data }) => {
    const { icon, iconFontSize, number, text, notANumber } = data;
    const [visible, setVisible] = useState(false);
    return (
        <Waypoint
            onEnter={() => setVisible(true)}
            onLeave={() => setVisible(false)}
        >
            <Container>
                <Icon iconFontSize={iconFontSize}>{icon}</Icon>
                <Number>
                    {visible &&
                        (notANumber ? (
                            number
                        ) : (
                            <AnimatedNumber
                                value={number}
                                formatValue={(value) =>
                                    `${+value.toFixed(0)}${
                                        number.includes("+") ? "+" : ""
                                    }`
                                }
                                duration={800}
                            />
                        ))}
                </Number>
                <Text>
                    {typeof text === "object" ? (
                        <>
                            {text[0]}
                            <br />
                            {text[1]}
                        </>
                    ) : (
                        text
                    )}
                </Text>
            </Container>
        </Waypoint>
    );
};

export default WhatIsIncludedCard;
