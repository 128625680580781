import { useRef } from "react";
import useVideoPlayer from "../../Hooks/useVideoPlayer";
import { AiOutlinePause } from "react-icons/ai";
import {
    BsFillPlayFill,
    BsFillVolumeUpFill,
    BsVolumeMuteFill,
} from "react-icons/bs";
import "./styles.css";

const VideoPlayer = ({ url, posterURL, width }) => {
    const videoElement = useRef(null);
    const {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
        handleVideoSpeed,
        toggleMute,
    } = useVideoPlayer(videoElement);
    return (
        <div className="container" style={width ? { width: "50%" } : {}}>
            <div className="video-wrapper">
                <video
                    src={url}
                    ref={videoElement}
                    onTimeUpdate={handleOnTimeUpdate}
                    poster={posterURL}
                />
                <div className="controls">
                    <div className="actions">
                        <button onClick={togglePlay}>
                            {!playerState.isPlaying ? (
                                <BsFillPlayFill />
                            ) : (
                                <AiOutlinePause />
                            )}
                        </button>
                    </div>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={playerState.progress}
                        onChange={(e) => handleVideoProgress(e)}
                    />
                    <select
                        className="velocity"
                        value={playerState.speed}
                        onChange={(e) => handleVideoSpeed(e)}
                    >
                        <option value="0.50">0.50x</option>
                        <option value="1">1x</option>
                        <option value="1.25">1.25x</option>
                        <option value="2">2x</option>
                    </select>
                    <button className="mute-btn" onClick={toggleMute}>
                        {!playerState.isMuted ? (
                            <BsFillVolumeUpFill />
                        ) : (
                            <BsVolumeMuteFill />
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;
