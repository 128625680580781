import React from "react";
import {
    Container,
    Content,
    Email,
    Logo,
    MailUs,
    MailUsHeading,
    Social,
    Socials,
} from "./styles.jsx";
import logo from "../../logo.png";
import { NavLink } from "react-router-dom";
import { BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";

const Footer = () => {
    const socials = [
        {
            icon: <BsInstagram />,
            link: "https://instagram.com/",
        },
        {
            icon: <BsYoutube />,
            link: "https://youtube.com/",
        },
        {
            icon: <BsLinkedin />,
            link: "https://linkedin.com/",
        },
    ];
    return (
        <Container>
            <Content>
                <div>
                    <NavLink to="/">
                        <Logo src={logo} />
                    </NavLink>
                    <MailUs>
                        <MailUsHeading>any query? mail us</MailUsHeading>
                        <Email>hello@moneymonitors.in</Email>
                    </MailUs>
                </div>
                <Socials>
                    {socials.map((social, index) => (
                        <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Social>{social.icon}</Social>
                        </a>
                    ))}
                </Socials>
            </Content>
        </Container>
    );
};

export default Footer;
