import React from "react";
import { Fade } from "react-reveal";
import { SectionHeading } from "../../globalStyles";
import ReviewCard from "../ReviewCard/ReviewCard";
import { Container, Content } from "./styles";

const Reviews = () => {
    return (
        <Container>
            <SectionHeading maxWidth="370">
                What our
                <span> students </span>
                say about us 💬
            </SectionHeading>
            <Fade bottom>
                <Content>
                    <ReviewCard />
                    <ReviewCard />
                    <ReviewCard />
                    <ReviewCard />
                </Content>
            </Fade>
        </Container>
    );
};

export default Reviews;
