import styled, { keyframes } from "styled-components";

export const Container = styled.div`
    padding: 0px 15px;
`;

export const Content = styled.div`
    max-width: 1170px;
    margin: auto;
    display: flex;
    align-items: center;
    @media (max-width: 1170px) {
        flex-direction: column;
        & > div {
            width: 100%;
        }
        & > div:last-child {
            padding-left: 0px;
        }
        max-width: 768px;
    }
    @media (max-width: 768px) {
        max-width: 575px;
    }
`;

export const Fly = keyframes`
    0%, 100% {
        transform: translateY(5%);
    }
    50% {
        transform: translateY(0);
    }
`;

export const Left = styled.div`
    width: 55%;
    position: relative;
    text-align: center;
    & img:first-child {
        width: 80%;
    }
    /* & img:last-child {
        position: absolute;
        width: 100%;
        top: 0px;
        height: 100%;
        left: 10px;
        animation: ${Fly} 6s cubic-bezier(0.75, 0.02, 0.31, 0.87) infinite;
    } */
`;

export const Right = styled.div`
    width: 45%;
    color: #a1a2b3;
    padding: 5rem 0rem 5rem 4rem;
    @media screen and (max-width: 890px) {
        padding-bottom: 0px;
    }
`;

export const Heading = styled.div`
    margin-bottom: 1.5rem;
    font-family: CalSans-SemiBold, sans-serif;
    font-size: 1.75rem;
    color: #fff;
`;

export const Desc1 = styled.div`
    margin-bottom: 2rem;
    font-size: 1.25rem;
    color: #a1a2b3;
`;

export const Desc2 = styled.div``;
