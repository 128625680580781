import React from "react";
import { Container, Content } from "./styles";
import { SectionHeading } from "../../globalStyles";
import Monitor from "../Monitor/Monitor";

const OurMonitors = () => {
    const monitors = [
        {
            name: "Sushant Bindal",
            video: "https://monitorsvideos.s3.ir-thr-at1.arvanstorage.com/Sushant%201.mp4",
            image: "https://ik.imagekit.io/n9uyscagf/sushant_1IBXvyPU6.png",
        },
        {
            name: "Aaditya Iyengar",
            video: "https://monitorsvideos.s3.ir-thr-at1.arvanstorage.com/Aditya%20Mulitcam%201%20English.mp4",
            image: "https://ik.imagekit.io/n9uyscagf/aaditya_0IPdmoUm4.png",
        },
        {
            name: "Reshi Magada",
            video: "https://monitorsvideos.s3.ir-thr-at1.arvanstorage.com/Reshi%201.mp4",
            image: "https://ik.imagekit.io/n9uyscagf/reshi_0P7DojZNw.png",
        },
    ];
    return (
        <Container>
            <SectionHeading maxWidth="380">
                Meet your
                <span> monitors 🧑‍🏫 </span>
            </SectionHeading>
            <Content>
                {monitors.map((monitor, index) => (
                    <Monitor monitor={monitor} key={index} />
                ))}
            </Content>
        </Container>
    );
};

export default OurMonitors;
