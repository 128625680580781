import React from "react";
import { Fade } from "react-reveal";
import { SectionHeading } from "../../globalStyles";
import {
    Container,
    Content,
    Desc1,
    Desc2,
    Heading,
    Left,
    Right,
} from "./styles";

const Intro = () => {
    return (
        <Container>
            <SectionHeading maxWidth="670" capitalize>
                Why <span>Money Monitors?</span>
            </SectionHeading>
            <Fade bottom>
                <Content>
                    <Left>
                        <img
                            src="https://ik.imagekit.io/n9uyscagf/MM_U-sl4AaVj.png"
                            alt="Intro"
                        />
                        {/* <img
                            src="/assets/images/introimgtop.png"
                            alt="Intro Top"
                        /> */}
                    </Left>
                    <Right>
                        <Heading>This is not just a course.</Heading>
                        <Desc1>
                            You’ll get the exclusive lifetime access to our
                            community, where all our monitors and industry
                            leaders in finance will come together.
                        </Desc1>
                        <Desc2>
                            They’ll be sharing ideas and their learnings on
                            anything and everything related to finance via
                            Videos, Newsletters, Documents, Important articles
                            and they will also host live batches on specific
                            topics like PERSONAL FINANCE, INVESTMENTS, CRYPTO
                            and much more.
                        </Desc2>
                    </Right>
                </Content>
            </Fade>
        </Container>
    );
};

export default Intro;
