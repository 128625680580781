import React from "react";
import { SectionHeading } from "../../globalStyles";
import WhatIsIncludedCard from "../WhatIsIncludedCard/WhatIsIncludedCard";
import { Container, Content } from "./styles";
import { AiFillClockCircle } from "react-icons/ai";
import { BsFillPlayFill, BsFillCalendarEventFill } from "react-icons/bs";
import { GoFileSubmodule } from "react-icons/go";
import { IoIosPeople } from "react-icons/io";
import { Fade } from "react-reveal";

const WhatIsIncluded = () => {
    const whatIsIncluded = [
        {
            icon: <BsFillCalendarEventFill />,
            iconFontSize: "40",
            number: "1",
            text: ["Mega", "Seminar"],
        },
        {
            icon: <BsFillPlayFill />,
            iconFontSize: "70",
            number: "8",
            text: ["Live", "Classes"],
        },
        {
            icon: <GoFileSubmodule />,
            iconFontSize: "50",
            number: "10+",
            text: "Modules",
        },
        {
            icon: <AiFillClockCircle />,
            iconFontSize: "60",
            number: "15+",
            text: ["Hours Of", "Content"],
        },
        {
            icon: <IoIosPeople />,
            iconFontSize: "60",
            number: "∞",
            notANumber: true,
            text: ["Free Access To", "The Community"],
        },
    ];
    return (
        <Container>
            <SectionHeading>
                What's
                <span> included? </span>
                📦
            </SectionHeading>
            <Fade bottom>
                <Content>
                    {whatIsIncluded.map((item, index) => (
                        <WhatIsIncludedCard data={item} key={index} />
                    ))}
                </Content>
            </Fade>
        </Container>
    );
};

export default WhatIsIncluded;
