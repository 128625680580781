import React from "react";
import {
    Container,
    Review,
    StarRating,
    User,
    UserName,
    UserNameProfession,
    UserPic,
    UserProfession,
} from "./styles";
import ReactStars from "react-rating-stars-component";
import { BsStarFill, BsStar } from "react-icons/bs";

const ReviewCard = () => {
    const starProps = {
        size: 20,
        value: 2.5,
        edit: false,
        filledIcon: <BsStarFill />,
        emptyIcon: <BsStar />,
    };
    return (
        <Container>
            <User>
                <UserPic url="https://deothemes.com/envato/xhibiter/html/img/products/item_10.jpg" />
                <UserNameProfession>
                    <UserName>Tushar Vaswani</UserName>
                    <UserProfession>Web Developer</UserProfession>
                </UserNameProfession>
            </User>
            <StarRating>
                <ReactStars
                    activeColor="#ffd359"
                    color="#c4c5cf"
                    {...starProps}
                />
            </StarRating>
            <Review>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum eros tellus, sodales eget tincidunt vitae, aliquet ut
                nibh. Pellentesque et libero nisi.
            </Review>
        </Container>
    );
};

export default ReviewCard;
