import styled from "styled-components";

export const LinksButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    @media screen and (max-width: 540px) {
        gap: 1rem;
    }
`;

export const Container = styled.div`
    padding: 0px 1.5rem;
    position: fixed;
    z-index: 3;
    top: 0px;
    display: block;
    width: 100%;
    ${({ blur }) =>
        blur &&
        `
            background: rgba(17, 17, 17, 0.74);
            backdrop-filter: blur(8px);
        `}
    ${({ hamMenuOpen }) =>
        hamMenuOpen &&
        `
            background: #1a1a1a;
            ${LinksButtons} > a {
                display: none;
            }
        `}
`;

export const Content = styled.div`
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    @media screen and (max-width: 800px) {
        height: 80px;
    }
`;

export const Logo = styled.img`
    width: 60px;
`;

export const Links = styled.ul`
    display: flex;
    @media screen and (max-width: 540px) {
        display: none;
    }
`;

export const Link = styled.li`
    list-style-type: none;
    padding: 0.875rem 1.25rem;
    font-weight: 400;
    font-family: CalSans-SemiBold, sans-serif;
    &:hover {
        color: #fda722;
    }
`;

export const HeaderButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const HamMenu = styled.div`
    display: none;
    @media screen and (max-width: 540px) {
        display: block;
    }
`;

export const HeaderButton = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    background: hsla(0, 0%, 100%, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    color: #fff;
    &:hover {
        background: #fda722;
    }
`;
